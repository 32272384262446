import { privateAPI } from '.';

export const userAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getUserDetail: build.query({
      query: () => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return {
          url: '/client-portal/get-user-detail',
          headers: {
            'X-Time-Zone': timezone,
          },
        };
      },
      providesTags: ['GetUserDetails'],
    }),
    updatePassword: build.mutation({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        Object.entries(body).forEach(([key, value]) => {
          if (value) {
            formData.append(key, value);
          }
        });

        return {
          url: '/client-portal/profile/update-password',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['UpdatePassword'],
    }),
  }),
});

export const { useGetUserDetailQuery, useUpdatePasswordMutation } = userAPI;
