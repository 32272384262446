import NotFound from 'containers/pages/not-found';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet } from 'react-router-dom';

function ProtectedRoutes({ hasSupportTicketsAccess, hasClientMeetingsAccess }) {
  const { pathname } = window.location;

  if (pathname.startsWith('/client-requests') && !hasSupportTicketsAccess) {
    return <NotFound />;
  }

  if (pathname.startsWith('/meetings') && !hasClientMeetingsAccess) {
    return <NotFound />;
  }

  return <Outlet />;
}

ProtectedRoutes.propTypes = {
  hasSupportTicketsAccess: PropTypes.bool.isRequired,
  hasClientMeetingsAccess: PropTypes.bool.isRequired,
};

export default ProtectedRoutes;
